script
<template>
    <v-container class="single-comment px-0 pt-4">
        <h4
            class="text-subtitle d-inline-block"
            v-if="card && card.question"
        >
            {{ card.question }}
            <span
                class="font-italic"
                v-text="$t('common.seeExample')"
            ></span>
            <v-icon
                color="primary"
                @click="openDialogInfo"
            >
                mdi-help-circle
            </v-icon>
        </h4>

        <div class="single-comment__input d-flex mx-auto mt-4">
            <v-textarea
                v-model="textInput"
                outlined
                :label="$tc('evaluation.commentLabel', isMandatory ? 2 : 1)"
                counter
            />
        </div>

        <confirm-dialog ref="confirm" />
    </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';

export default {
    name: 'SingleTextComment',
    components: {},
    props: {
        gameId: {
            type: Number,
            required: true
        },
        card: {
            type: Object,
            required: true
        },
        isAllowedEditQuestion: {
            type: Boolean
        },
        userAnswer: {
            type: Object
        },
        evaluatedUserId: {
            type: Number,
            required: true
        },
        cardLayoutsMap: {
            type: [Object, null],
            required: true
        },
        isMandatory: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            textInput: ''
        };
    },
    watch: {
        userAnswer: {
            handler(newValue, oldValue) {
                if (newValue?.answered) {
                    this.textInput = newValue?.answered;
                }
            },
            immediate: true
        },
        textInput: {
            handler(newValue, oldValue) {
                this.changeComment(newValue);
            }
        }
    },
    computed: {
        ...mapGetters(['getUser'])
    },

    methods: {
        changeComment(comment) {
            const answer = {
                gameId: this.gameId,
                cardId: this.card.id,
                evaluatedUserId: this.evaluatedUserId,
                answered: comment
            };

            this.$emit('changeAnswer', answer);
        },

        openDialogInfo() {
            this.$refs.confirm.open(this.$t('common.example'), this.card.feedforward, { noCancel: true });
        }
    }
};
</script>

<style lang="scss" scoped>
.single-comment {
    &__input {
        width: 100%;
    }
}
</style>

script
<template>
    <v-container class="current-evaluation px-0 pt-3">
        <h4
            class="text-subtitle"
            v-html="`<b>${textPrepend}</b> ${card?.question}`"
        />

        <div class="current-evaluation__rating d-flex mx-auto">
            <div>
                <v-rating
                    hover
                    :length="getChoicesToShow.max"
                    :size="32"
                    v-model="rating"
                >
                    <template v-slot:item="props">
                        <div
                            class="d-inline-flex flex-column justify-center align-center cursor-pointer"
                            @click="rating = props.index + 1"
                        >
                            <span v-text="props.index + 1" />

                            <v-icon
                                :color="props.value === props.index + 1 ? 'yellow darken-4' : ''"
                                v-text="`mdi-circle${props.value === props.index + 1 ? '' : '-outline'}`"
                                class="pt-0"
                            />
                        </div>
                    </template>
                </v-rating>

                <div class="d-flex flex-wrap justify-space-between">
                    <span
                        v-text="$t('evaluation.almostNever')"
                        class="word-by-line"
                    />
                    <span
                        v-text="$t('evaluation.almostAlways')"
                        class="word-by-line"
                    />
                </div>
            </div>

            <div class="ml-auto">
                <span v-text="$t('evaluation.noEvaluate')" />

                <v-checkbox
                    v-model="disableEvaluation"
                    class="mt-0 pt-0 pl-3"
                    color="yellow darken-4"
                    hide-details
                />
            </div>
        </div>
    </v-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import EvaluationsAPIService from '@/services/EvaluationsAPIService';

export default {
    name: 'SinglePointsEvaluation',
    components: {},
    props: {
        gameId: {
            type: Number,
            required: true
        },
        card: {
            type: Object,
            required: true
        },
        isAllowedEditQuestion: {
            type: Boolean
        },
        userAnswer: {
            type: Object
        },
        evaluatedUserId: {
            type: Number,
            required: true
        },
        cardLayoutsMap: {
            type: [Object, null],
            required: true
        },
        textPrepend: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            selectedItem: null
        };
    },
    watch: {
        card: {
            handler(newValue, oldValue) {}
        }
    },
    computed: {
        ...mapGetters(['getUser']),

        rating: {
            get() {
                const rating = this.userAnswer?.answered;

                return rating === null || rating === undefined ? null : Number(rating);
            },
            set(newRating) {
                this.answerQuestion(newRating);
            }
        },

        disableEvaluation: {
            get() {
                return this.rating === 0;
            },
            set(newValue) {
                this.rating = newValue === true ? 0 : null;
            }
        },

        disableQuestion() {
            return !this.isAllowedEditQuestion;
        },

        getChoicesToShow() {
            const { format } = this.cardLayoutsMap[this.card.card_layout_id];

            const formatSplitted = format.split(':');
            const ranges = formatSplitted[1].split('-');

            return {
                format: formatSplitted[0],
                min: Number(ranges[0]),
                max: Number(ranges[1])
            };
        }
    },

    methods: {
        ...mapMutations('activities', ['setUserAnswersForCardId']),

        async answerQuestion(rating) {
            try {
                const answer = {
                    gameId: this.gameId,
                    cardId: this.card.id,
                    evaluatedUserId: this.evaluatedUserId
                };

                this.$emit('changeAnswer', { ...answer, answered: rating });

                const result = await EvaluationsAPIService.answerQuestion({ ...answer, answer: rating });
            } catch (error) {
                console.log('error', error);

                this.$store.dispatch('snackbar/error', this.$t('common.errorSavingData'));

                if (error.response) {
                    console.log(error.response.data.error); // "invalid_credentials"
                    console.log(error.response.status);
                }
            }
        },

        /*
        getImagePath(image) {
            return image ? process.env.VUE_APP_API_ENDPOINT + 'media/imagesSimple?filename=games/cards/' + image : '';
        },*/

        setUserState(user, state) {
            //this.$set(this.usersActives, user.id + '', state);
        }
    }
};
</script>

<style lang="scss" scoped>
.current-evaluation {
    &__rating {
        max-width: 28rem;

        @media (max-width: 600px) {
            .v-rating .v-icon {
                padding-left: 0.375rem;
                padding-right: 0.375rem;
            }
        }
    }
}
.answer {
    min-height: 1.875rem;
}

.word-by-line {
    display: table-caption;
    width: 3.85rem;
    text-align: center;
}
</style>

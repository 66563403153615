<template>
    <v-container class="evaluations px-0">
        <div
            v-if="postInfo.image"
            class="d-flex justify-center"
        >
            <img
                :src="postInfo.image"
                height="100px"
            />
        </div>

        <!-- <gameStatusComponent
            v-if="gameStatus"
            :gameStatus="gameStatus"
        /> -->

        <h4
            v-text="$t('evaluation.evaluatedQuestions', { count: countCardsEvaluated, total: countCardsToEvaluate })"
            class="text-danger text-center"
        />

        <h4
            v-html="$t('evaluation.evaluating', { person: evaluatedUser?.name || '...' })"
            class="text-center text-subtitle"
        />

        <div class="evaluations__scroll-area">
            <div class="mb-0 pb-0">
                <template v-for="(card, index) in cards">
                    <SinglePointsEvaluation
                        v-if="isCardSinglePointsEvaluation(card)"
                        :key="card.id"
                        :card="card"
                        :gameId="gameId"
                        :userAnswer="answered[card.id]"
                        :isAllowedEditQuestion="isAllowedEditQuestion"
                        :evaluatedUserId="evaluatedUserId"
                        :cardLayoutsMap="cardLayoutsMap"
                        :textPrepend="`${index + 1}/${countCardsToEvaluate}`"
                        @changeAnswer="changeAnswer"
                        @showInfoDialog="showInfoDialog"
                    />
                </template>

                <div
                    class="mt-8 mb-4"
                    v-if="isVisibleTextFreeComponent"
                >
                    <strong
                        class="d-block text-uppercase text-red text-center"
                        v-text="$t('common.important')"
                    />
                    <div
                        class="mt-2"
                        v-html="$t('evaluation.statementFreeQuestion')"
                    />
                </div>

                <template v-for="(card, index) in cards">
                    <SingleTextComment
                        v-if="!isCardSinglePointsEvaluation(card) && isVisibleTextFreeComponent"
                        :key="`comment-${card.id}`"
                        :card="card"
                        :gameId="gameId"
                        :userAnswer="answered[card.id]"
                        :isAllowedEditQuestion="isAllowedEditQuestion"
                        :evaluatedUserId="evaluatedUserId"
                        :cardLayoutsMap="cardLayoutsMap"
                        :isMandatory="isMandatoryTextFreeComponent"
                        @changeAnswer="changeAnswer"
                        @showInfoDialog="showInfoDialog"
                        ref="itemsComments"
                    />
                </template>
            </div>

            <div class="buttons-control pb-1 d-flex justify-center">
                <c-btn
                    v-if="!isAllowedEditGame"
                    @click="exitFromGame"
                    :outlined="false"
                    :text="false"
                    :dark="true"
                    minWidth="160"
                    large
                >
                    {{ $t('games.questions.buttons.exit') }}
                </c-btn>

                <c-btn
                    v-else
                    :disabled="!canFinishEvaluation"
                    @click="finishEvaluation"
                    :outlined="false"
                    :text="false"
                    :dark="canFinishEvaluation"
                    minWidth="160"
                    large
                >
                    {{ $t('common.dialogSave') }}
                </c-btn>
            </div>
        </div>

        <confirm-dialog ref="confirm" />
    </v-container>
</template>

<script>
import axios from '@/plugins/axios';
import { mapGetters } from 'vuex';
import EvaluationsAPIService from '@/services/EvaluationsAPIService';
import SinglePointsEvaluation from '@/components/games/evaluations/SinglePointsEvaluation';
import SingleTextComment from '@/components/games/evaluations/SingleTextComment';

const MINIMUM_TEXT_COMPONENT_LENGTH = 50;

export default {
    name: 'EvaluationsComponent',
    components: {
        SinglePointsEvaluation,
        SingleTextComment
        // gameStatusComponent: () => import('@/components/games/questions/gameStatus')
    },
    props: {
        gameId: {
            type: Number,
            required: true
        },
        evaluatedUserId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            cards: [],
            cardLayoutsMap: {},
            answered: {},
            /*allowModifyGameAfterFinishing: null,
      isGameEndedForUser: null,*/
            isAllowedEditGame: false,
            cardsSolutions: null,
            gameStatus: null,
            evaluatedUser: {}
        };
    },
    computed: {
        ...mapGetters(['getUser', 'getColorPrimary', 'getColorSecondary']),
        ...mapGetters('cache', ['getPosts']),

        postInfo() {
            const url = 'evaluate/users';
            let currentPost = this.getPosts.find(post => post.attributes.url === url);
            if (!currentPost) {
                currentPost = {
                    attributes: {
                        title2: '',
                        image2: ''
                    }
                };
            }

            const { title2: title, image2 } = currentPost.attributes;
            const image = image2
                ? `${process.env.VUE_APP_API_ENDPOINT}media/imagesSimple?filename=posts/images/${image2}`
                : '';

            return { title, image };
        },

        isAllowedEditQuestion() {
            return this.isAllowedEditGame;
        },

        isGameFinished() {
            return !this.isAllowedEditGame || this.gameStatus?.finished_at ? true : false;
        },

        canFinishEvaluation() {
            return (
                this.countCardsCompleted === this.cardsToComplete?.length ||
                (!this.isMandatoryTextFreeComponent && this.countCardsEvaluated === this.countCardsToEvaluate)
            );
        },

        cardsToComplete() {
            return this.cards; //this.cards.filter(this.isCardSinglePointsEvaluation);
        },

        countCardsToEvaluate() {
            return this.cards.filter(this.isCardSinglePointsEvaluation).length;
        },

        countCardsCompleted() {
            return this.cardsToComplete.filter(card => {
                const answer = this.answered[card.id];

                const { answered } = answer ?? {};

                if (this.isCardSinglePointsEvaluation(card)) {
                    return !isNaN(answered);
                }

                return answered?.length >= MINIMUM_TEXT_COMPONENT_LENGTH;
            }).length;
        },

        countCardsEvaluated() {
            return this.cardsToComplete.filter(card => {
                const answer = this.answered[card.id];

                const { answered } = answer ?? {};

                if (this.isCardSinglePointsEvaluation(card)) {
                    return !isNaN(answered);
                }

                return 0;
            }).length;
        },

        isMandatoryTextFreeComponent() {
            return this.isVisibleTextFreeComponent && this.getUser.category !== 'G09';
        },

        isVisibleTextFreeComponent() {
            return this.getUser.id != this.evaluatedUserId;
        }
    },

    async created() {
        await this.fetchData();
    },

    destroyed() {},

    methods: {
        async fetchData() {
            this.$store.dispatch('loading/start');

            try {
                const data = await EvaluationsAPIService.getMyCurrentState({
                    gameId: this.gameId,
                    evaluatedUserId: this.evaluatedUserId
                });

                this.initialize(data.data);
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'));
            }

            this.$store.dispatch('loading/end');
        },

        initialize(data) {
            if (data.cardLayouts) {
                this.cardLayoutsMap = data.cardLayouts?.reduce((result, currentLayout) => {
                    result[currentLayout.id] = currentLayout;

                    return result;
                }, {});
            }

            if (data.cards) {
                this.cards = data.cards;
            }

            const answeredMap = {};
            data.answered?.forEach(ans => {
                answeredMap[ans.card_id] = ans;
            });
            this.answered = answeredMap;

            // this.allowModifyGameAfterFinishing = Boolean(data.allowModifyGameAfterFinishing);
            // this.isGameEndedForUser = Boolean(data.isGameEndedForUser);

            this.isAllowedEditGame = data.isAllowedEditGame;

            const cardsSolutionsMap = data.cardsSolutions?.length ? {} : null;
            data.cardsSolutions?.forEach(cardS => {
                cardsSolutionsMap[cardS.id] = cardS;
            });
            this.cardsSolutions = cardsSolutionsMap;

            if (data.answered.length < this.cards.length) {
                // this.currentCardNumber = data.answered.length;
            }

            if (data.gameStatus) {
                this.gameStatus = data.gameStatus;
            }

            this.evaluatedUser = data.evaluatedUser;
        },

        changeAnswer(answer) {
            if (!answer) return null;

            if (!this.answered) {
                this.answered = {};
            }

            if (answer?.answered || answer?.answered == 0) {
                this.$set(this.answered, answer?.cardId, answer);
            } else {
                this.$delete(this.answered, answer?.cardId);
            }
        },

        async finishEvaluation() {
            const answers = [];

            if (this.isVisibleTextFreeComponent) {
                const comments = this.$refs.itemsComments;
                for (const item of comments) {
                    const { id: cardId, card_layout_id: cardLayoutId } = item.card ?? {};

                    const answer = item.textInput;

                    if (answer?.length < MINIMUM_TEXT_COMPONENT_LENGTH && this.isMandatoryTextFreeComponent) {
                        return this.$store.dispatch('snackbar/error', this.$t('evaluation.validation.comment'));
                    }

                    answers.push({ cardId, cardLayoutId, answer });
                }
            }

            try {
                this.$store.dispatch('loading/start');

                const url = `/games/evaluations/finishGame/${this.gameId}/${this.evaluatedUserId}`;

                const result = await axios.post(url, { answers });

                if (result.status === 200) {
                    this.exitFromGame();
                }

                this.$store.dispatch('loading/end');
            } catch (error) {
                this.$store.dispatch('loading/end');

                let msg = 'common.errorSavingData';
                if (error.response) {
                    const firstError = error.response?.data?.errors?.length && error.response.data.errors[0]?.detail;
                    if (firstError) {
                        msg = `games.evaluations.errors.${firstError}`;

                        if (firstError === 'need_complete') {
                        }
                    }
                    // console.log(firstError) // "invalid_credentials"
                    // console.log(error.response.status)
                }

                this.$store.dispatch('snackbar/error', this.$t(msg));
            }
        },

        async showInfoDialog({ title, info }) {
            const options = {
                color: 'primary',
                width: 800,
                zIndex: 200,
                noCancel: true,
                noOk: false
            };

            if (!title) {
                title = this.$t('common.infoTitle');
            }

            await this.$refs.confirm.open(title, info, options);
        },

        exitFromGame() {
            this.$router.push({ name: 'users-to-evaluate' });
        },

        isCardSinglePointsEvaluation(card) {
            const { format } = this.cardLayoutsMap[card?.card_layout_id] ?? {};

            return format.startsWith('points');
        }
    }
};
</script>

<style scoped lang="scss">
.evaluations {
    &__scroll-area {
        overflow-y: auto;
        max-height: calc(100vh - 350px);
    }
}
.buttons-control {
    & > * {
        margin-top: 10px;
    }
}

@media (max-width: 600px) {
    .buttons-control {
    }
}
</style>
